import { Card, Col, Tag } from 'antd';
import { each, find } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { locationObjToString } from 'util/location';
import CardDescriptions, { CardDescriptionsItem } from '../../../../components/Descriptions/CardDescription';
import { EVENT, LAYOUT } from '../../../../constants/App';
import { getAthleteCardOrganization } from 'util/player';

/***
 * used on ticket review and subscription verification
 * with slightly different data eg phone with/without prefix "+"
 */
class PlayerData extends Component {
    renderHeader(ticket, race, index, t) {
        if (ticket) {
            return (
                <div>
                    <span>{`${t('subscription')} ${index} - ${ticket.title}`}</span>
                    <span style={{ float: 'right', fontSize: 20 }}>
                        <i className={``}></i>
                    </span>
                </div>
            );
        } else if (race) {
            return (
                <div>
                    <span>
                        {t('subscription')} - {race.name}
                    </span>
                </div>
            );
        } else {
            return (
                <div>
                    <span>{t('subscription')}</span>
                </div>
            );
        }
    }

    renderCustomFields(race, player, t) {
        let displayedCustomFields = [];

        each(race.customFields, (cf) => {
            if (player[cf.name] && player[cf.name] !== '') {
                let item = {};

                //var langObj = string.getTranslation(cf.langs, i18n.language);

                if (cf.type === 'select_choice') {
                    let selectedOption = find(cf.options, function (o) {
                        return o.value == player[cf.name];
                    });

                    item.value = selectedOption ? selectedOption.label : player[cf.name];
                } else if (cf.type === 'check_box') {
                    item.value = t(player[cf.name] ? player[cf.name] : 'no');
                } else {
                    item.value = player[cf.name];
                }

                item.label = cf.label;

                displayedCustomFields.push(item);
            }
        });

        return displayedCustomFields.map((item, i) => {
            return (
                <CardDescriptionsItem key={`cfv_${i}`} label={item.label}>
                    {item.value ? item.value : t('not_provided')}
                </CardDescriptionsItem>
            );
        });
    }

    renderProducts(race, player) {        

        return (race.products || []).filter((product) => player[`product_${product.id}`]).map((product) => {
            return (
                <CardDescriptionsItem key={`product_${product.id}`} label={product.name}>
                    {product.variants.map((variant) => {
                        if (player[`product_${product.id}`] === variant.sku) {
                            return (
                                <div key={`variant_${variant.sku}`}>
                                    {variant.attributes.map((attribute) => {
                                        return (
                                            <span key={`attribute_${attribute.attribute_name}`}>
                                                {attribute.attribute_name}: {attribute.name}
                                            </span>
                                        );
                                    })}
                                </div>
                            );
                        }
                    })}
                </CardDescriptionsItem>
            );
        }); 
    }

    renderLocation(player, t) {
        if (player.location_label) {
            return <CardDescriptionsItem label={t('location')}>{player.location_label}</CardDescriptionsItem>;
        } else if (player.location) {
            return (
                <CardDescriptionsItem label={t('location')}>
                    {locationObjToString(player.location)}
                </CardDescriptionsItem>
            );
        }
        return null;
    }

    renderAthleteCard(race, player, t) {
        const cardItems = [];

        if (player.athlete_card) {
            const field = race.standardFields.athlete_card;
            const option = find(field.options, (o) => o.value === player.athlete_card);
            if (option) {
                cardItems.push(<CardDescriptionsItem label={t('athlete_card')}>{option.label}</CardDescriptionsItem>);

                if (player.athlete_card_number) {
                    cardItems.push(
                        <CardDescriptionsItem label={t('athlete_card_number')}>
                            {player.athlete_card_number}
                        </CardDescriptionsItem>
                    );
                }

                if (player.athlete_card_provider_organization_name) {
                    cardItems.push(
                        <CardDescriptionsItem label={t('athlete_card_organization')}>
                            {getAthleteCardOrganization(player, option)}
                        </CardDescriptionsItem>
                    );
                }
            }
        }
        return cardItems;
    }

    render() {
        const { i18n, t, ticket, race, index, player, team, competition } = this.props;

        const teamTransKey = competition.type === EVENT.TYPE.TRAVEL ? 'group' : 'team';

        return (
            <Col xs={24}>
                <Card
                    className="gx-card"
                    title={this.renderHeader(ticket, race, index, t)}
                    extra={[
                        <span key="1">{team ? <Tag color={LAYOUT.PRIMARY_COLOR}>{t(teamTransKey)}</Tag> : null}</span>,
                    ]}
                >
                    <CardDescriptions>
                        <CardDescriptionsItem label={t('first_name')}>{player.first_name}</CardDescriptionsItem>
                        <CardDescriptionsItem label={t('last_name')}>{player.last_name}</CardDescriptionsItem>
                        <CardDescriptionsItem column={{ xs: 1, md: 2 }} label={t('email')}>
                            {player.email}
                        </CardDescriptionsItem>
                        <CardDescriptionsItem label={t('gender')}>
                            {player.gender === 'm' ? t('male') : t('female')}
                        </CardDescriptionsItem>
                        <CardDescriptionsItem label={t('birthday')}>
                            {moment(player.birthday, 'YYYY-MM-DD').format('LL')}
                        </CardDescriptionsItem>
                        {this.renderLocation(player, t)}
                        {player.citizenship && (
                            <CardDescriptionsItem label={t('citizenship_country')}>
                                {player.citizenship_label ? player.citizenship_label : player.citizenship}
                            </CardDescriptionsItem>
                        )}
                        {player.phone && player.phone.length > 0 && (
                            <CardDescriptionsItem label={t('phone')}>{`${player.phone[0] === '+' ? '' : '+'}${
                                player.phone
                            }`}</CardDescriptionsItem>
                        )}
                        {this.renderAthleteCard(race, player, t)}
                        
                        {this.renderCustomFields(race, player, t, i18n)}

                        {this.renderProducts(race, player, t, i18n)}
                    </CardDescriptions>
                    {/* handle extension */}
                    {this.props.children}
                </Card>
            </Col>
        );
    }
}

PlayerData.defaultProps = {};

export default withTranslation(['common'])(PlayerData);
